/**
 * Fetcher Bottle
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FStation extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/station/', '/Home/Gangping/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取气站列表（气站列表）
    getStationList(search, pagination, sorter) {
        const url = this.spellURL('getStationList', 'station_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取气站列表（筛选项）
    getStationListAsSelect() {
        const url = this.spellURL('getStationListFilter', 'getStationField');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.id,
                name: item.name,
                stationCode: item.stationCode,
            }));
        });
    }

    // 获取授权气站（筛选项）
    getAuthStationsAsSelect() {
        const url = this.spellURL('getAuthStationsFilter', 'getAuthStation');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.id,
                name: item.name,
            }));
        });
    }

    // [废弃] 获取充装专用气站列表（筛选项）
    getFillStationListAsSelect() {
        const url = this.spellURL('getFillStationListFilter', 'getFillStationField');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.id,
                name: item.station,
            }));
        });
    }

    // 获取气站详情（气站列表）
    getStationDetail(search) {
        const url = this.spellURL('getStationDetail', 'stationInfo');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存气站（气站列表）
    saveStation(station) {
        const url = this.spellURL('saveStation', 'api_station');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(station)),
            station_id: station.id,
            type: station.id ? 'edit' : 'add',
        };
        delete send.id;
        return this.post(url, send);
    }

    // 获取设备列表（气站设备）
    getEquipmentList(search, pagination, sorter) {
        const url = this.spellURL('getEquipmentList', 'station_equipment');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取设备详情（气站设备）
    getEquipmentDetail(search) {
        const url = this.spellURL('getEquipmentDetail', 'equipmentInfo');
        const send = {
            ...search,
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存设备（气站设备）
    saveEquipment(equipment) {
        const url = this.spellURL('saveEquipment', 'saveEquipment');
        const send = {
            ...equipment,
            ...this.transKeyName('underline', equipment),
            eq_id: equipment.id,
            op_type: 'edit',
        };
        return this.post(url, send);
    }

    // 获取气站重瓶列表（气站重瓶列表）
    getStationHeavyBottles(search, pagination, sorter) {
        const url = this.spellURL('getStationHeavyBottles', 'station_inventory');
        const send = {
            ...search,
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            site_id: search.stationId,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取气站轻瓶列表（气站轻瓶列表）
    getStationLightBottles(search, pagination, sorter) {
        const url = this.spellURL('getStationLightBottles', 'station_inventory');
        const send = {
            ...search,
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            site_id: search.stationId,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取气站气瓶流转信息（气站重瓶库存、气站轻瓶库存）
    getStationBottleTransit(transit) {
        const url = this.spellURL('getStationBottleTransit', '../Area/inventory_detail');
        const send = {
            ...this.transKeyName('underline', transit),
        };
        return this.post(url, send);
    }

    // 获取枪状态（智能枪设备列表）
    getGunStatus(search) {
        const url = this.spellURL('getGunStatus', 'device_status');
        const send = {
            ...search,
        };
        return this.post(url, send);
    }

    // 获取电子秤列表（智能充装设备）
    getElcEquipment() {
        const url = this.spellURL('getElcEquipment', 'getElcEquipment');
        return this.post(url);
    }

    // 保存盒子绑定气站（智能枪设备列表）
    saveBoxBindStation(bindData) {
        const url = this.spellURL('saveBoxBindStation', '../Gangping/bind_station');
        const send = {
            ...this.transKeyName('underline', bindData),
        };
        return this.post(url, send);
    }

    // 保存充装枪绑定充装员（智能枪设备列表）
    saveGunBindWorker(bindData) {
        const url = this.spellURL('saveGunBindWorker', '../Gangping/bind_fill_person');
        const send = {
            ...this.transKeyName('underline', bindData),
        };
        return this.post(url, send);
    }

    // 获取设备充装统计（当日充装）
    getDeviceFilling(search) {
        const url = this.spellURL('getDeviceFilling', 'deviceFill');
        const send = {
            ...search,
        };
        return this.post(url, send);
    }

    // 获取充装统计（充装统计）
    getFillingStatistic(search) {
        const url = this.spellURL('getFillingStatistic', 'records_statistics');
        const send = {
            ...search,
            type: 1,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取充装计件（充装计件）
    getFillingPieces(search) {
        const url = this.spellURL('getFillingPieces', 'fillPieceStatistic');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取充装计件明细（充装计件）
    getFillingPieceDetail(search, pagination, sorter) {
        const url = this.spellURL('getFillingPieceDetail', 'fillPieceDetail');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取充前检查（充前检查）
    getFillingFrontCheck(search, pagination, sorter) {
        const url = this.spellURL('getFillingFrontCheck', 'check_front');
        const newSearch = {
            ...this.transKeyName('underline', search),
        };
        const send = {
            ...newSearch,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取充前检查-导出地址（充前检查）
    getFillingFrontCheckExportUrl(search) {
        const url = this.spellURL(
            '../common/defaultExportUrl',
            '../Gangping/export_check_front.html',
        );
        const send = {
            ...search,
            datetime: search.datetime?.replace(',', ' - '),
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return this.get(url, send, null, { timeout: 300 * 1000 }).then(res => {
                if (!res) {
                    return null;
                }
                let url2 = this.spellURL('', '../Index/downloadExport');
                return {
                    url: this.parseParamUrl(url2, { filename: res.file || res.data }),
                };
            });
        }
    }

    // 获取充后检查（充后检查）
    getFillingBackCheck(search, pagination, sorter) {
        const url = this.spellURL('getFillingBackCheck', 'check_end');
        const newSearch = {
            ...this.transKeyName('underline', search),
        };
        const send = {
            ...newSearch,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取充后检查-导出地址（充后检查）
    getFillingBackCheckExportUrl(search) {
        const url = this.spellURL(
            '../common/defaultExportUrl',
            '../Gangping/export_check_end.html',
        );
        const send = {
            ...search,
            datetime: search.datetime?.replace(',', ' - '),
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return this.get(url, send, null, { timeout: 300 * 1000 }).then(res => {
                if (!res) {
                    return null;
                }
                let url2 = this.spellURL('', '../Index/downloadExport');
                return {
                    url: this.parseParamUrl(url2, { filename: res.file || res.data }),
                };
            });
        }
    }

    // 保存检测记录（充前检查、充后检查）
    saveFillingCheck(check, checkType) {
        const url = this.spellURL('saveFillingCheck', 'add_check_record');
        const send = {
            ...check,
            ...this.transKeyName('underline', check),
            checkType: checkType === 'back' ? 'end' : checkType,
            check_type: checkType === 'back' ? 'end' : checkType,
            time: check.checkTime,
        };
        return this.post(url, send);
    }

    // 获取充装记录（充装记录）
    getFillingRecordList(search, pagination, sorter) {
        const url = this.spellURL('getFillingRecordList', 'record_list');
        const newSearch = {
            ...this.transKeyName('underline', search),
        };
        const send = {
            ...newSearch,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 异步导出充装记录全部（充装记录）
    saveExportRecordList(search) {
        const url = this.spellURL('saveExportRecordList', 'exportRecordList');
        const send = {
            ...this.transKeyName('underline', search),
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 保存充装记录监管平台显示百分百（充装记录）
    saveRegulatorPercent(percent) {
        const url = this.spellURL('saveRegulatorPercent', '../Setting/rcd_percent_setting');
        const send = {
            ...this.transKeyName('underline', percent),
        };
        return this.post(url, send);
    }

    // 获取充装授权列表（充装授权）
    getFillingAuthList(search, pagination, sorter) {
        const url = this.spellURL('getFillingAuthList', 'getFillAuth');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存添加充装授权（充装授权）
    saveAddFillingAuth() {
        const url = this.spellURL('saveAddFillingAuth', 'addFillAuth');
        const send = {};
        return this.post(url, send);
    }

    // 保存申请充装授权（充装授权）
    saveApplyFillingAuth(auth) {
        const url = this.spellURL('saveApplyFillingAuth', 'applyFillAuth');
        const send = {
            ...this.transKeyName('underline', auth),
        };
        return this.post(url, send);
    }

    // 获取审核充装授权时的气站列表（充装授权）
    getAuditFillingStations(audit) {
        const url = this.spellURL('getAuditFillingStations', 'getFillAuthStation');
        const send = {
            ...this.transKeyName('underline', audit),
        };
        return this.post(url, send);
    }

    // 保存充装授权审核结果（充装授权）
    saveAuditFillingAuth(audit) {
        const url = this.spellURL('saveAuditFillingAuth', 'checkFillAuth');
        const send = {
            ...this.transKeyName('underline', audit),
        };
        return this.post(url, send);
    }

    // 保存撤销充装授权（充装授权）
    saveCancelFillingAuth(auth) {
        const url = this.spellURL('saveCancelFillingAuth', 'cancelFillAuth');
        const send = {
            ...this.transKeyName('underline', auth),
        };
        return this.post(url, send);
    }

    // 保存充装授权设置（充装授权）
    saveFillingAuthSetting(setting) {
        const url = this.spellURL('saveFillingAuthSetting', 'updateFillAuthRegister');
        const send = {
            ...this.transKeyName('underline', setting),
        };
        return this.post(url, send);
    }

    // 获取充装记录-导出地址（充装记录）
    getFillingRecordExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', '../Gangping/export_records');
        const send = {
            ...search,
            date: search.date?.replace(',', ' - '),
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取工业气充前检查（工业气充前检查）
    getIndustryFrontCheck(search, pagination, sorter) {
        const url = this.spellURL('getIndustryFrontCheck', '../Gangping/front_check_industrial');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取工业气过程检查（工业气过程检查）
    getIndustryProcessCheck(search, pagination, sorter) {
        const url = this.spellURL(
            'getIndustryProcessCheck',
            '../Gangping/filling_check_industrial',
        );
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取工业气充后检查（工业气充后检查）
    getIndustryAfterCheck(search, pagination, sorter) {
        const url = this.spellURL('getIndustryAfterCheck', '../Gangping/end_check_industrial');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取工业气充后检查（工业气充后检查）
    getIndustryFillRecord(search, pagination, sorter) {
        const url = this.spellURL('getIndustryFillRecord', '../Gangping/industrial_record_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取残重记录（残重记录）
    getResidualRecords(search, pagination, sorter) {
        const url = this.spellURL('getResidualRecords', '../Gangping/residual_record');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取对接平台(气瓶检验记录、充装记录、充前检查、充后检查）
    getExtUploadCfg(searchSet) {
        const { station } = searchSet;
        const url = this.spellURL('getExtUploadCfg', 'getExtUploadCfg');
        return this.post(url, {
            station_id: station,
        });
    }

    // 获取残重分析（残重分析）
    getResidualAnaly(search) {
        const url = this.spellURL('getResidualAnaly', '../User/residual_analy');
        const send = {
            ...this.transKeyName('underline', { ...search }),
        };
        return this.post(url, send);
    }

    // 获取残重分析详情（残重分析）
    getResidualAnalyDetail(search) {
        const url = this.spellURL('getResidualAnalyDetail', '../User/residual_analy_detail');
        const send = {
            ...this.transKeyName('underline', { ...search }),
        };
        return this.post(url, send);
    }

    // 获取气站配置列表（对接配置-表格数据）
    getLockingConfigurationList(search, sorter) {
        const url = this.spellURL(
            'getLockingConfigurationList',
            'getPlatformAbutmentStationConfigList',
        );
        const send = {
            ...this.transKeyName('underline', { ...search }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 获取气站对接平台（对接配置-对接平台）
    getPlatformAbutmentConfigList() {
        const url = this.spellURL('getPlatformAbutmentConfigList', 'getPlatformAbutmentConfigList');
        // const send = {
        //     ...this.transKeyName('underline', { ...search }),
        // };
        return this.post(url);
    }

    // 保存&修改新增的配置（对接配置）
    savePlatformAbutmentConfigList(params) {
        const url = this.spellURL(
            'savePlatformAbutmentConfigList',
            'setPlatformAbutmentStationConfig',
        );
        const send = {
            ...this.transKeyName('underline', { ...params }),
        };
        return this.post(url, send);
    }

    // 删除对接平台配置与气站（对接配置）
    onSaveDeleteLockingConfiguration(send) {
        const url = this.spellURL(
            'onSaveDeleteLockingConfiguration',
            'deletePlatformAbutmentStationConfig',
        );
        return this.post(url, send);
    }

    // 获取充装预警列表（充装预警）
    getFillingWarningList(search, pagination, sorter) {
        const url = this.spellURL(
            'getFillingWarningList',
            '../../lpg/admin/fillingManagement/gasFilling/alarmList',
        );
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
                fillDate: search.fillDate?.replace(',', ' - '),
                sortBy: sorter?.field,
                sortOrder: sorter?.order,
            }),
        };
        return this.post(url, send);
    }
}

export const $fetchStation = new FStation();
