/**
 * Fetcher Bottle
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FBottle extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/bottle/', '/Home/Gangping/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 读取气瓶国标规格（筛选项）
    getBottleModelsAsSelect(explain = 'onExplain') {
        const url = this.spellURL('getBottleModels', 'getBottleModelField');
        const send = {
            page: 1,
            limit: 100,
        };
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return res.rows.map(item => ({
                id: item.id,
                name: item.name,
                explain:
                    explain === 'onExplain' ? item.minSpec + 'KG ~ ' + item.maxSpec + 'KG' : '',
            }));
        });
    }

    // 读取气瓶充装规格列表（充装规格）
    getBottleSpecifications(search, pagination, sorter) {
        const url = this.spellURL('getBottleSpecifications', '../Guige/guige_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 读取气瓶充装规格（筛选项）
    getBottleSpecAsSelect(type = 'normal') {
        const url = this.spellURL('getBottleSpecsFilter', 'getBottleSpecField');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => {
                const item2 = {
                    id: item.id,
                    name: item.name,
                };
                if (type === 'model') {
                    item2.modelId = item.modelId;
                }
                return item2;
            });
        });
    }

    // 气瓶充装规格详情（充装规格）
    getBottleSpecificationDetail(search) {
        const url = this.spellURL('getBottleSpecificationDetail', '../Guige/guigeInfo');
        const send = {
            ...search,
        };
        return this.post(url, send);
    }

    // 保存气瓶充装规格（充装规格）
    saveBottleSpecification(specification) {
        const url = this.spellURL('saveBottleSpecification', '../Guige/saveGuige');
        const { grossWeight, ...resetSpec } = this.trimEndsSpace(specification);
        const send = {
            ...this.transKeyName('underline', resetSpec),
            grossWeight,
            type: specification.id ? 'edit' : 'add',
        };
        return this.post(url, send);
    }

    // 保存删除充装规格（充装规格）
    saveDeleteFillSpec(spec) {
        const url = this.spellURL('saveDeleteFillSpec', '../Guige/delBottleSpec');
        const send = {
            ...this.transKeyName('underline', spec),
        };
        return this.post(url, send);
    }

    // 获取气瓶押金规格（押金规格）
    getDepositSpecification() {
        const url = this.spellURL('getDepositSpecification', 'getDepositSepcField');
        const send = {};
        return this.post(url, send);
    }

    // 获取气瓶押金规格（筛选项）
    getDepositSpecAsSelect() {
        const url = this.spellURL('getDepositSpecification', 'getDepositSepcField');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.id,
                name: item.name,
            }));
        });
    }

    // 保存气瓶押金规格（押金规格）
    saveDepositSpecification(spec) {
        const url = this.spellURL('saveDepositSpecification', 'setDepositSpec');
        const send = {
            ...this.trimEndsSpace(spec),
        };
        return this.post(url, send);
    }

    // 获取气瓶押金规格与气瓶规格的关联（押金规格）
    getDepositModels() {
        const url = this.spellURL('getDepositModels', '../Guige/model_list');
        const send = {};
        return this.post(url, send);
    }

    // 保存气瓶押金规定绑定气瓶规格（押金规格）
    saveDepositBindModels(models) {
        const url = this.spellURL('saveDepositBindModels', '../Guige/api_model_deposit');
        const send = {
            ...this.transKeyName('underline', models),
        };
        return this.post(url, send);
    }

    // 获取气瓶档案（气瓶档案）
    getBottleArchives(search, pagination, sorter) {
        const url = this.spellURL('getBottleArchives', 'archive');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                manuDate: 'manu_date',
                pullDate: 'pulldate',
                lastdate: 'lastdate',
                nextDate: 'nextdate',
            }[sorter.field],
            sortOrder: sorter.order,
            ext_sync: search.extSync,
            insure_status: search.insureStatus,
            insure_date: search.insureDate?.replace(',', ' - '),
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
            pulldate: search.pulldate?.replace(',', ' - '),
            ext_platform: search.extPlatform,
            ext_status: search.extStatus,
            safe_assess: search.safeAssess,
        };
        delete send.manuDate;
        delete send.extSync;
        delete send.insureDate;
        delete send.insureStatus;
        delete send.extPlatform;
        delete send.extStatus;
        delete send.safeAssess;
        return this.post(url, send);
    }

    // 获取气瓶档案（气瓶档案工业气）
    getIndustryArchives(search, pagination, sorter) {
        const url = this.spellURL('getIndustryArchives', 'archive_industrial');
        const send = {
            ...search,
            ext_sync: search.extSync,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                manuDate: 'manu_date',
                pullDate: 'pulldate',
                lastdate: 'lastdate',
                nextDate: 'nextdate',
            }[sorter.field],
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
            pulldate: search.pulldate?.replace(',', ' - '),
        };
        delete send.manuDate;
        delete send.extSync;
        return this.post(url, send);
    }

    // 获取气瓶档案-导出地址（气瓶档案）
    getBottleArchiveExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_archive.html');
        const send = {
            ...search,
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
        };
        delete send.manuDate;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return this.get(url, send, null, { timeout: 300 * 1000 }).then(res => {
                if (!res) {
                    return null;
                }
                let url2 = this.spellURL('', '../Index/downloadExport');
                return {
                    url: this.parseParamUrl(url2, { filename: res.file || res.data }),
                };
            });
        }
    }

    // 保存气瓶投保日期（气瓶档案）
    saveBottleInsureDate(insureDate) {
        const url = this.spellURL('saveBottleInsureDate', 'change_archive_insure');
        const send = {
            ...this.transKeyName('underline', insureDate),
        };
        return this.post(url, send);
    }

    // 获取批量投保文件专用上传地址
    getInsureUploadURL() {
        return this.spellURL('../common/uploadFile', '../Upload/uploadInsureExcel');
    }

    // 获取批量拦截文件专用上传地址
    getInterceptUploadURL() {
        return this.spellURL('../common/uploadFile', '../Upload/upload');
    }

    // 获取气瓶检验记录列表
    getBottleInspectionRecords(search, sorter) {
        const url = this.spellURL('getBottleInspectionRecords', 'bottle_inpsect_records');
        const send = {
            ...this.transKeyName('underline', search),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 气瓶检验记录获取批量导入文件专用上传地址
    getBatchUploadURL() {
        return this.spellURL('../common/uploadFile', '../upload/upload');
    }

    // 气瓶检验记录保存批量导入
    saveBatchUpload(file) {
        const url = this.spellURL('saveBatchUpload', 'bottle_inpsect_import');
        const send = {
            ...this.transKeyName('underline', file),
        };
        return this.post(url, send);
    }

    // 保存批量投保结果
    saveBatchInsure(insure) {
        const url = this.spellURL('saveBatchInsure', 'batch_insure');
        const send = {
            ...this.transKeyName('underline', insure),
        };
        return this.post(url, send);
    }

    // 保存批量拦截结果
    saveBatchIntercept(intercept) {
        const url = this.spellURL('saveBatchIntercept', 'batch_intercept');
        const send = {
            ...this.transKeyName('underline', intercept),
        };
        return this.post(url, send);
    }

    // 保存气瓶拦截（气瓶档案）
    saveBottleIntercept(intercept) {
        const url = this.spellURL('saveBottleIntercept', 'archiveHold');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(intercept)),
        };
        return this.post(url, send);
    }

    // 获取气瓶已加入拦截列表（气瓶档案）
    getBottleInterceptList(search, pagination, sorter) {
        const url = this.spellURL('getBottleInterceptList', 'getHoldList');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存取消气瓶拦截（气瓶档案）
    saveCancelBottleIntercept(cancel) {
        const url = this.spellURL('saveCancelBottleIntercept', 'backHold');
        const send = {
            ...this.transKeyName('underline', cancel),
        };
        return this.post(url, send);
    }

    // 读取导出登记档案对接配置（气瓶档案）
    getExtRegConf(station) {
        const url = this.spellURL('getExtRegConf', 'getExtUploadCfg');
        const send = {
            ...this.transKeyName('underline', station),
        };
        return this.post(url, send);
    }

    // 保存导出登记档案发起导出（气瓶档案）
    saveExtRegStart(condition) {
        const url = this.spellURL('saveExtRegStart', 'exportArchiveByTsg');
        const send = {
            ...this.transKeyName('underline', condition),
        };
        return this.post(url, send);
    }

    // 读取导出登记档案导出结果（气瓶档案）
    getExtRegResult(ext) {
        const url = this.spellURL('getExtRegResult', 'getExportArchiveResult');
        const send = {
            ...this.transKeyName('underline', ext),
        };
        return this.post(url, send);
    }

    // 获取过检气瓶列表（过检气瓶）
    getBottleExpiredList(search, pagination, sorter) {
        const url = this.spellURL('getBottleExpiredList', 'detection_obsolete');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                manuDate: 'manu_date',
                pullDate: 'pulldate',
                lastdate: 'lastdate',
                nextDate: 'nextdate',
            }[sorter.field],
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
        };
        delete send.manuDate;
        return this.post(url, send, [
            ['rows.[]', 'pulldate', 'pullDate'],
            ['rows.[]', 'nextdate', 'nextDate'],
        ]);
    }

    // 获取临近检测气瓶（临检气瓶）
    getBottlesNearCheck(search, pagination, sorter) {
        const url = this.spellURL('getBottlesNearCheck', 'nearCheck');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                manuDate: 'manu_date',
                pullDate: 'pulldate',
                lastdate: 'lastdate',
                nextDate: 'nextdate',
            }[sorter.field],
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
        };
        delete send.manuDate;
        return this.post(url, send, [
            ['rows.[]', 'pulldate', 'pullDate'],
            ['rows.[]', 'nextdate', 'nextDate'],
        ]);
    }

    // 获取报废气瓶列表（报废气瓶）
    getBottleScrapList(search, pagination, sorter) {
        const url = this.spellURL('getBottleScrapList', 'scrap_bottle_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                manuDate: 'manu_date',
                pullDate: 'pulldate',
                lastdate: 'lastdate',
                nextDate: 'nextdate',
            }[sorter.field],
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
            manu_date: search.manuDate?.replace(',', ' - '),
        };
        delete send.manuDate;
        return this.post(url, send, [
            ['rows.[]', 'pulldate', 'pullDate'],
            ['rows.[]', 'nextdate', 'nextDate'],
        ]);
    }

    // 获取气瓶送检单列表（送检单列表）
    getBottleListCheckLog(search, pagination, sorter) {
        const url = this.spellURL('getBottleListCheckLog', 'applyList');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
        };
        return this.post(url, send);
    }

    // 获取气瓶送检单的气瓶（送检单列表）
    getBottleCheckBottles(check) {
        const url = this.spellURL('getBottleCheckBottles', 'getApplyInfo');
        const send = {
            ...this.transKeyName('underline', check),
        };
        return this.post(url, send);
    }

    // 获取气瓶报废记录（报废记录）
    getBottleListScrapLog(search, pagination, sorter) {
        const url = this.spellURL('getBottleListScrapLog', 'getOperateLog');
        const send = {
            ...this.transKeyName('underline', search),
            operate_type: 2,
            page: pagination.current,
            limit: pagination.pageSize,
        };
        return this.post(url, send);
    }

    // 获取钢瓶资产汇总列表（钢瓶资产汇总）
    getBottleAssetSummary(search, pagination, sorter) {
        const url = this.spellURL('getBottleAssetSummary', 'asset_summarty');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 钢瓶资产汇总后端异步导出（钢瓶资产汇总）
    saveExportBottleAsset(search) {
        const url = this.spellURL('saveExportBottleAsset', 'exportCylinderAssets');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取钢瓶资产汇总后端异步导出结果（钢瓶资产汇总）
    getExportBottleAssetResult(result) {
        const url = this.spellURL('getExportBottleAssetResult', 'getExportArchiveResult');
        const send = {
            ...this.transKeyName('underline', result),
        };
        return this.post(url, send);
    }

    // 获取气瓶统计数据（气瓶分布）
    getBottleStatistic() {
        const url = this.spellURL('getBottleStatistic', 'duration');
        const send = {};
        return this.post(url, send);
    }

    // 获取气瓶分析数据（气瓶分布）
    getBottleAnalysis() {
        const url = this.spellURL('getBottleAnalysis', 'bottle_duration');
        // 开发模式
        if (Fetcher.inDevMod) {
            const send = {};
            return this.post(url, send);
        }
        // 线上模式，合并请求
        else {
            const team = [
                this.post(url, { type: 'station' }),
                this.post(url, { type: 'duration' }),
                this.post(url, { type: 'guige' }),
                this.post(url, { type: 'position' }),
                this.post(url, { type: 'area' }),
                this.post(url, { type: 'store' }),
            ];
            return Promise.all(team).then(resList => {
                return {
                    station: resList[0].rows,
                    duration: resList[1].durations,
                    specifications: resList[1].specs,
                    spec: resList[2].rows,
                    position: resList[3],
                    area: resList[4].rows,
                    store: resList[5].rows,
                };
            });
        }
    }

    getBottleRechargeRate() {
        const url = this.spellURL('getBottleRechargeRate', 'get_record_analyse');
        const send = {};
        return this.post(url, send);
    }

    getOverdueBottles(search, pagination, sorter) {
        const url = this.spellURL('getOverdueBottles', 'abnormal_bottle');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取气瓶地图（气瓶地图）
    getBottleMap(search, pagination) {
        const url = this.spellURL('getBottleMap', 'map');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send, [['', 'totalpage', 'totalPage']]);
    }

    // 获取气站气瓶数（气站气瓶）
    getStationBottles(search, pagination, sorter) {
        const url = this.spellURL('getStationBottles', 'station_bottle_list');
        const send = {
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取气站气瓶按类型分的列表（气站气瓶）
    getStationBottleTypeList(search) {
        const url = this.spellURL('getStationBottleTypeList', 'station_bottle_spec_count');
        const send = {
            ...search,
            station_id: search.id,
        };
        return this.post(url, send);
    }

    // 获取会员气瓶列表（会员气瓶列表）
    getUserBottleList(search, pagination, sorter) {
        const url = this.spellURL('getUserBottleList', 'map_list');
        const send = {
            ...search,
            sale_id: search.saleId,
            datetime: search.datetime?.replace(',', ' - '),
            min_gas_period: search.gasPeriod?.split(',')[0],
            max_gas_period: search.gasPeriod?.split(',')[1],
            min_dep_per: search.depositPer?.split(',')[0],
            max_dep_per: search.depositPer?.split(',')[1],
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                gasPeriod: 'gas_period',
                deposit: 'deposit',
                depositPercent: 'deposit_percent',
            }[sorter.field],
            sortOrder: sorter.order,
        };
        delete send.gasPeriod;
        delete send.depositPer;
        delete send.saleId;
        return this.post(url, send, [['rows.[]', 'shopname', 'shopName']]);
    }

    // 获取会员列表气瓶数总计（会员气瓶列表）
    getUsersBottleTotal() {
        const url = this.spellURL('getUsersBottleTotal', 'totalGangping');
        const send = {};
        return this.post(url, send);
    }

    // 获取会员气瓶押金列表（气瓶押金）
    getUsersDepositList(search, pagination, sorter) {
        const url = this.spellURL('getUsersDepositList', '../User/deposit_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取会员气瓶押金-导出地址（气瓶押金）
    getBottleDepositExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', '../User/export_deposit');
        const send = {
            ...search,
            datetime: search.date?.replace(',', ' - '),
            date_type: search.dateType,
        };
        delete send.date;
        delete send.dateType;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取会员气瓶押金总计（气瓶押金）
    getUsersDepositTotal() {
        const url = this.spellURL('getUsersDepositTotal', '../User/totalDeposit');
        const send = {};
        return this.post(url, send);
    }

    // 获取会员气瓶押金详情（气瓶押金）
    getUserDepositDetail(search) {
        const url = this.spellURL('../User/getUserDepositDetail', '../User/deposit_detail');
        const send = {
            deposit_id: search.id,
        };
        return this.post(url, send);
    }

    // 保存押金退款（气瓶押金）
    saveUserDepositRefund(deposit) {
        const url = this.spellURL('saveUserDepositRefund', '../User/back_deposit');
        const send = {
            ...this.transKeyName('underline', deposit),
        };
        return this.post(url, send);
    }

    // 读取气瓶押金配置列表（气瓶押金配置）
    getBottleDepositSetting(search) {
        const url = this.spellURL('getBottleDepositSetting', 'deposit_config_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: 1,
            limit: 200,
        };
        return this.post(url, send);
    }

    // 保存气瓶押金配置内容（气瓶押金配置）
    saveBottleDepositSetting(deposit) {
        const url = this.spellURL('saveBottleDepositSetting', 'deposit_config_api');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(deposit)),
        };
        return this.post(url, send);
    }

    // 保存气瓶押金配置状态（气瓶押金配置）
    saveBottleDepositState(deposit) {
        const url = this.spellURL('saveBottleDepositState', 'updateDepositstatus');
        const send = {
            ...deposit,
        };
        return this.post(url, send);
    }

    // 获取押金配置会员可见性（气瓶押金配置）
    getDepositVisibility(deposit) {
        const url = this.spellURL('getDepositVisibility', 'memberDeposit');
        const send = {
            ...deposit,
        };
        return this.post(url, send);
    }

    // 保存押金配置会员可见性（气瓶押金配置）
    saveDepositVisibility(visibility) {
        const url = this.spellURL('saveDepositVisibility', 'updateDepositmember');
        const send = {
            ...this.transKeyName('underline', visibility),
        };
        return this.post(url, send);
    }

    // 获取气瓶流转列表（气瓶流转）
    getBottleCirculation(search, pagination, sorter) {
        const url = this.spellURL('getBottleCirculation', 'bottle_position');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取气瓶转移前所有者（气瓶流转）
    getBottleTransferOwner(search) {
        const url = this.spellURL('getBottleTransferOwner', 'findBottleOwner');
        const send = {
            ...search,
        };
        return this.post(url, send);
    }

    // 获取气瓶转移目标信息（气瓶流转）
    getBottleTransferTarget(search) {
        const url = this.spellURL('getBottleTransferTarget', 'findBottleTarget');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存气瓶转移（气瓶流转）
    saveBottleTransfer(bottle) {
        const url = this.spellURL('saveBottleTransfer', 'bottleExchange');
        const send = {
            ...this.transKeyName('underline', bottle),
        };
        return this.post(url, send);
    }

    // 获取带瓶入户配置（带瓶入户配置）
    getBottleEnterPrice() {
        const url = this.spellURL('getBottleEnterPrice', 'self_into_list');
        const send = {};
        return this.post(url, send);
    }

    // 保存带瓶入户配置修改（带瓶入户配置）
    saveBottleEnterPrice(prices) {
        const url = this.spellURL('saveBottleEnterPrice', 'self_into_api');
        const send = {
            ...this.transKeyName('underline', prices),
        };
        return this.post(url, send);
    }

    // 获取在用过检报废气瓶列表（在用过检报废气瓶）
    getBottleUsedInspectionList(search, sorter) {
        const url = this.spellURL(
            'getBottleUsedInspectionList',
            '../../lpg/admin/bottleManagement/alert/overInspectionAndScrapList',
        );
        const send = {
            ...this.transKeyName('underline', search),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 获取气瓶配送充装比列表（配送充装比）
    getDeliveryFillingRatioList(search) {
        const url = this.spellURL(
            'getDeliveryFillingRatioList',
            '../../lpg/admin/bottleManagement/statistics/deliveryFillingRatioList',
        );
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取配送员气瓶列表（配送员气瓶列表）
    getDeliveryBottlesList(search, pagination, sorter) {
        const url = this.spellURL('getDeliveryBottlesList', '../Worker/deliveryBottles');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }
}

export const $fetchBottle = new FBottle();
