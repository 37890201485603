/**
 * BXlsxExport
 * @author Tevin
 */

import moment from 'moment';
import React from 'react';
import innerText from 'react-innertext';
import { writeFileXLSX, utils } from 'xlsx';
import { Table } from 'antd';

export class BXlsxExport extends React.Component {
    constructor(props) {
        super(props);
        this._export = {
            // 工作模式，cur 当前页面导出、merge 全部页面导出
            mode: '',
            workData: [[]],
            colSizes: [],
            rowSizes: [],
            mergeSets: [],
            // 平化的列设置
            columnsFlat: [],
            // 标题两行标记
            titleDoubled: false,
            // 标题合并标记
            titleMerges: [],
            // 标题字段列
            titleFields: [[], []],
            // 表格内容类型
            contentTypes: [],
            // 表格数据
            datas: [],
        };
    }

    graspRowDatasFromTable() {
        const tableData = [];
        this.$refs.container
            .querySelector('.ant-table-tbody')
            .querySelectorAll('.ant-table-row')
            .forEach(row => {
                const rowData = [];
                row.querySelectorAll('.ant-table-cell').forEach(cell => {
                    rowData.push(cell.innerText || cell.textContent);
                });
                tableData.push(rowData);
            });
        const rows = tableData.map(() => []);
        this._export.columnsFlat.forEach((column, index) => {
            // 非数据列跳过
            if (!column.dataIndex) {
                return;
            }
            tableData.forEach((rowData, rowIndex) => {
                rows[rowIndex].push(rowData[index]);
            });
        });
        rows.forEach(row => {
            // 反序添加
            // 后端先反序，按从早到晚的顺序发送数据，前端再反序，顺序回正
            // 用于解决导出过程中产生新数据的问题
            this._export.datas.unshift(row);
        });
    }

    clearRowDatas() {
        this._export.datas = [];
    }

    // 两位序数转excel列编号
    _encodingColIndex(index) {
        const secondIndex = index % 26;
        const firstIndex = parseInt(index / 26);
        const secondStr = String.fromCharCode(65 + secondIndex);
        const firstStr = firstIndex === 0 ? '' : String.fromCharCode(65 + firstIndex - 1);
        return firstStr + secondStr;
    }

    _createTitleRow() {
        this._export.colSizes = [];
        // 单行
        if (!this._export.titleDoubled) {
            this._export.titleFields[0].forEach(field => {
                this._export.workData[0].push(field.content);
                this._export.colSizes.push({ wpx: field.width });
            });
        }
        // 两行
        else {
            this._export.titleFields[0].forEach((fieldRow1, index) => {
                const fieldRow2 = this._export.titleFields[1][index];
                this._export.workData[0].push(fieldRow1 ? fieldRow1.content : null);
                this._export.workData[1].push(fieldRow2 ? fieldRow2.content : null);
                this._export.colSizes.push({
                    wpx: fieldRow2 ? fieldRow2.width : fieldRow1.width,
                });
                // 合并
                const curMerge = this._export.titleMerges[index];
                if (curMerge) {
                    // 向下合并
                    if (curMerge.type === 'col') {
                        const colCode = this._encodingColIndex(index);
                        this._export.mergeSets.push(
                            utils.decode_range(colCode + '1:' + colCode + '2'),
                        );
                    }
                    // 向右合并
                    else if (curMerge.type === 'row') {
                        const colStart = this._encodingColIndex(index);
                        const colEnd = this._encodingColIndex(index + curMerge.num - 1);
                        this._export.mergeSets.push(
                            utils.decode_range(colStart + '1:' + colEnd + '1'),
                        );
                    }
                }
            });
        }
    }

    _createContentRow() {
        this._export.rowSizes = this._export.titleDoubled
            ? [{ hpx: 24 }, { hpx: 24 }]
            : [{ hpx: 24 }];
        this._export.datas.forEach(row => {
            const rowArr = [];
            row.forEach((col, index) => {
                let content = (col || '').replace(/^\s+|"|\s+$/g, '');
                // 普通内容列
                if (this._export.contentTypes[index] === 'normal') {
                    // 超过15位长度，视为字符串（防止转换为科学计数法显示）
                    if (content.length > 15) {
                        rowArr.push(content);
                    }
                    // 长度15位之内
                    else {
                        // 纯数字转数值
                        if (/^(-?\d+)(\.\d+)?$/.test(content)) {
                            rowArr.push(Number(content));
                        }
                        // 其他都视为字符串
                        else {
                            rowArr.push(content);
                        }
                    }
                }
                // 强制转字符串列
                else if (this._export.contentTypes[index] === 'string') {
                    rowArr.push(content);
                }
            });
            this._export.workData.push(rowArr);
            this._export.rowSizes.push({ hpx: 16 });
        });
    }

    onDownloadXLSX() {
        this._export.workData = this._export.titleDoubled ? [[], []] : [[]];
        // 标题
        this._createTitleRow();
        // 内容
        this._createContentRow();
        // excel数据转换
        const workSheet = utils.aoa_to_sheet(this._export.workData);
        workSheet['!cols'] = this._export.colSizes;
        workSheet['!rows'] = this._export.rowSizes;
        workSheet['!merges'] = this._export.mergeSets;
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, workSheet, this.props.fileName);
        // 下载
        let exportName;
        if (this._export.mode === 'cur') {
            exportName =
                this.props.fileName + '-第' + this.props.pageIndex + '页-' + moment().format('YYYYMMDD.HHmmss');
        } else if (this._export.mode === 'merge') {
            exportName = this.props.fileName + '-' + moment().format('YYYYMMDD.HHmm');
        }
        writeFileXLSX(workBook, exportName + '.xlsx', { bookType: 'xlsx' });
    }

    // 检查是否是双行标题
    _checkDoubleTitle() {
        this._export.titleDoubled = false;
        for (let column of this.props.columns) {
            if (column.children && column.children.length > 0) {
                this._export.titleDoubled = true;
                break;
            }
        }
    }

    _getTitleField(column) {
        const content = innerText(column.title);
        const colWidth =
            content.replace(/<.*?>/, '').replace(/[\u4e00-\u9fa5]/g, 'aa').length * 6;
        return {
            content,
            width: column.width ? parseInt(column.width * 0.7) : Math.max(70, colWidth),
        };
    }

    _addColumnTitle(type, column, child, parentIndex, childIndex) {
        this._export.contentTypes.push(column.export || 'normal');
        if (type === 'self') {
            this._export.titleFields[0].push(this._getTitleField(column));
            if (this._export.titleDoubled) {
                this._export.titleMerges.push({ type: 'col' });
                this._export.titleFields[1].push(null);
            } else {
                this._export.titleMerges.push({ type: 'none' });
            }
        } else if (type === 'child') {
            if (childIndex === 0) {
                this._export.titleMerges.push({ type: 'row', num: 1, parentIndex });
                this._export.titleFields[0].push(this._getTitleField(column));
            } else {
                this._export.titleMerges.push({ type: 'none' });
                this._export.titleFields[0].push(null);
                // 合并列数增加
                const mergeCounter = this._export.titleMerges.find(
                    merge => merge.parentIndex === parentIndex,
                );
                if (mergeCounter) {
                    mergeCounter.num += 1;
                }
            }
            this._export.titleFields[1].push(this._getTitleField(child));
        }
    }

    _flatteningColumns() {
        this._checkDoubleTitle();
        this._export.contentTypes = [];
        this._export.titleMerges = [];
        this._export.titleFields = [[], []];
        const defaultCol = {
            sorter: false,
            fixed: false,
        };
        this._export.columnsFlat = [];
        let columnIndex = -1;
        this.props.columns.forEach(column => {
            columnIndex++;
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                // 非数据列跳过
                if (!column.dataIndex) {
                    return;
                }
                this._export.columnsFlat.push({
                    ...column,
                    ...defaultCol,
                });
                this._addColumnTitle('self', column);
                // 补充扩充的列
                (this.props.extraColumns || []).forEach(col => {
                    if (column.dataIndex === col.afterIndex) {
                        this._export.columnsFlat.push({
                            ...col,
                            ...defaultCol,
                        });
                        this._addColumnTitle('self', col);
                    }
                });
            }
            // 存在子级的列
            else {
                column.children.forEach((child, index) => {
                    // 非数据列跳过
                    if (!child.dataIndex) {
                        return;
                    }
                    this._export.columnsFlat.push({
                        ...child,
                        ...defaultCol,
                        // 将两层 title 合并
                        title: '<' + column.title + '>\r\n' + child.title,
                    });
                    this._addColumnTitle('child', column, child, columnIndex, index);
                    // 补充扩充的列
                    (this.props.extraColumns || []).forEach(extraCol => {
                        if (child.dataIndex === extraCol.afterIndex) {
                            this._export.columnsFlat.push({
                                ...extraCol,
                                ...defaultCol,
                                // 将两层 title 合并
                                title: '<' + column.title + '>\r\n' + extraCol.title,
                            });
                            this._addColumnTitle(
                                'child',
                                column,
                                extraCol,
                                columnIndex,
                                -1,
                            );
                        }
                    });
                });
            }
        });
    }

    renderExportTable(working) {
        this._flatteningColumns();
        if (!working) {
            return null;
        }
        let dataResource;
        if (this._export.mode === 'cur') {
            dataResource = (this.props.dataSource || []).reverse();
        } else if (this._export.mode === 'merge') {
            dataResource = this.state.dataResource;
        }
        return (
            <div className="c-xlsx-export" ref={elm => (this.$refs.container = elm)}>
                <Table
                    columns={this._export.columnsFlat}
                    rowKey={row => row.id || row.key}
                    dataSource={dataResource}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </div>
        );
    }
}
