/**
 * Fetcher FinancialModule - 财务模块接口
 * @author chensi
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FFinancialModule extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/financialModule/', '/lpg/admin/erp/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取物品类型列表（物品类型）
    getGoodsTypeList(search, pagination, sorter) {
        const url = this.spellURL('getGoodsTypeList', '../Goods/getGoodsTypeList');
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                page: pagination.current,
                pageSize: pagination.pageSize,
            }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 获取物品类型列表 - 下拉框选项
    getGoodsTypeSelectList() {
        const url = this.spellURL('getGoodsTypeList', '../Goods/getGoodsTypeScreen');
        return this.post(url);
    }

    // 添加物品类型（物品类型）
    saveAddArticleTypes(data) {
        const url = this.spellURL('saveAddArticleTypes', '../Goods/addGoodsType');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 编辑物品类型（物品类型）
    saveEditArticleTypes(data) {
        const url = this.spellURL('saveEditArticleTypes', '../Goods/editGoodsType');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 获取物品规格列表（物品规格）
    getGoodsSpecList(search, pagination, sorter) {
        const url = this.spellURL('getGoodsSpecList', '../Goods/getGoodsSpecList');
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                page: pagination.current,
                pageSize: pagination.pageSize,
            }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 获取物品规格列表 - 下拉框选项
    getGoodsSpecSelectList() {
        const url = this.spellURL('getGoodsSpecList', '../Goods/getGoodsSpecScreen');
        return this.post(url);
    }

    // 添加物品规格（物品规格）
    saveAddArticleSpec(data) {
        const url = this.spellURL('saveAddArticleSpec', '../Goods/addGoodsSpec');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 编辑物品规格（物品规格）
    saveEditArticleSpec(data) {
        const url = this.spellURL('saveEditArticleSpec', '../Goods/editGoodsSpec');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 获取物品列表（物品管理）
    getGoodsList(search, pagination, sorter) {
        const url = this.spellURL('getGoodsList', '../Goods/getGoodsList');
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                page: pagination.current,
                pageSize: pagination.pageSize,
            }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 添加物品（物品管理）
    saveAddArticle(data) {
        const url = this.spellURL('saveAddArticle', '../Goods/addGoods');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 编辑物品（物品管理）
    saveEditArticle(data) {
        const url = this.spellURL('saveEditArticle', '../Goods/editGoods');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 获取供应商列表（供应商管理）
    getSupplierList(search, pagination, sorter) {
        const url = this.spellURL('getSupplierList', '../Supplier/getSupplierList');
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                page: pagination.current,
                pageSize: pagination.pageSize,
            }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 添加供应商（供应商管理）
    saveAddSupplier(data) {
        const url = this.spellURL('saveAddSupplier', '../Supplier/addSupplier');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 编辑供应商（供应商管理）
    saveEditSupplier(data) {
        const url = this.spellURL('saveEditSupplier', '../Supplier/editSupplier');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    // 获取仓库列表（仓库管理）
    getWareHouseList(search, pagination, sorter) {
        const url = this.spellURL('getWareHouseList', 'warehouse/warehouseList');
        const send = {
            ...this.transKeyName('underline', {
                ...search,
                pageNum: pagination.current,
                pageSize: pagination.pageSize,
            }),
            sortBy: sorter?.field,
            sortOrder: sorter?.order,
        };
        return this.post(url, send);
    }

    // 保存仓库（仓库管理）
    saveWareHouse(data) {
        const url = this.spellURL('saveWareHouse', 'warehouse/setWarehouseInfo');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }

    //删除仓库（仓库管理）
    saveDelWareHouse(data) {
        const url = this.spellURL('saveDelWareHouse', 'warehouse/deleteWarehouseInfo');
        const send = {
            ...this.transKeyName('underline', {
                ...data,
            }),
        };
        return this.post(url, send);
    }
}

export const $fetchFinancialModule = new FFinancialModule();
